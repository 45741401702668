import localesEn from './en/error.json';
import localesEs from './es/error.json';
import localesFr from './fr/error.json';
import localesKo from './ko/error.json';
import localesRu from './ru/error.json';
import localesZh from './zh/error.json';
import localesPt from './pt/error.json';
import localesDe from './de/error.json';

export default function getErrorLocales(locale) {

    switch (locale) {
        case 'pt':
            return localesPt
        case 'es':
            return localesEs
        case 'ru':
            return localesRu
        case 'fr':
            return localesFr
        case 'ko':
            return localesKo
        case 'zh':
            return localesZh
        case 'de':
            return localesDe
        default:
            return localesEn
    }
}
